import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

const PostRelated = ({ posts }) => (
    <>
        <div className="post-related global-special">
            <h2>
                <span>You might also like</span>
            </h2>
            {posts.map(post => (
                <article key={post.slug} className="item-{{@number}}">
                    <h3>
                        <Link to={`/${post.slug}`} className="global-underline">
                            {post.title}
                        </Link>
                    </h3>
                    <div className="global-meta">
                        <Link to={`/author/${post.primary_author.slug}`}>
                            {post.primary_author.name}
                        </Link>
                    </div>
                </article>
            ))}
        </div>
    </>
);

PostRelated.propTypes = {
    posts: PropTypes.array,
};

export default PostRelated;

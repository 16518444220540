import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const ReadingProgress = ({ target }) => {
    const [readingProgress, setReadingProgress] = useState(0);

    const scrollListener = () => {
        if (!target.current) {
            return;
        }

        const element = target.current;
        const totalHeight = element.clientHeight + 300;

        const windowScrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0;

        if (windowScrollTop === 0 || windowScrollTop > totalHeight) {
            // eslint-disable-next-line consistent-return
            return setReadingProgress(0);
        }

        console.log(windowScrollTop);

        setReadingProgress((windowScrollTop / totalHeight) * 100);
    };

    useEffect(() => {
        window.addEventListener("scroll", scrollListener);
        return () => window.removeEventListener("scroll", scrollListener);
    });

    return (
        <div
            className="reading-progress-bar"
            style={{ width: `${readingProgress}%` }}
        />
    );
};

ReadingProgress.propTypes = {
    target: PropTypes.string.isRequired,
};

export default ReadingProgress;

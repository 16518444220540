import React, { useState } from "react";
import API from "../../api";

const Subscribe = () => {
    const [email, setEmail] = useState("");

    const handleChange = (e) => {
        setEmail(e.target.value);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        API.post("members/api/send-magic-link", {
            email: email,
            emailType: "subscribe",
        }).then((res) => {
            console.log(res);
        });
    };
    return (
        <div className="subscribe-section">
            <div className="subscribe-wrap">
                <h3>Subscribe to new posts.</h3>
                <form
                    data-members-form="subscribe"
                    className="subscribe-form"
                    onSubmit={handleSubmit}
                >
                    <input
                        data-members-email
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        placeholder="Your email address"
                        aria-label="Your email address"
                        required
                    />
                    <button className="global-button" type="submit">
                        Subscribe
                    </button>
                    <div className="subscribe-alert">
                        <small className="alert-loading">
                            Processing your application
                        </small>
                        <small className="alert-success">
                            Great! Check your inbox and confirm your
                            subscription
                        </small>
                        <small className="alert-error">
                            There was an error sending the email
                        </small>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Subscribe;

/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { Layout } from "../components/common";
import { MetaData } from "../components/common/meta";
import ReadingProgress from "../components/ReadingProgress";
import { readingTime as readingTimeHelper } from "@tryghost/helpers";
import PostRelated from "../components/common/postRelated";
import Subscribe from "../components/common/subscribe";
import PrevNext from "../components/common/prevNextPost";
import bullet from "../images/bullet.png";
import resolution from "../images/resolution.png";

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const Post = ({ data, location, pageContext }) => {
    const [showAdvertisement, setAdvertisement] = useState(false);

    useEffect(() => {
        let listener = document.addEventListener("scroll", (e) => {
            var scrolled = document.scrollingElement.scrollTop;
            if (scrolled >= 1080) {
                setAdvertisement(true);
            } else if (scrolled <= 1080) {
                setAdvertisement(false);
            }
        });
        return () => {
            document.removeEventListener("scroll", listener);
        };
    }, [showAdvertisement]);
    const { next, previous } = pageContext;
    // console.log(pageContext);
    const post = data.ghostPost;
    // console.log("this is post object printed by Rehan", post);
    const relatedPosts = data.allGhostPost.nodes;
    const getTagClasses = () =>
        post.tags.map(({ slug }) => `tag-${slug}`).join(" ");
    const readingTime = readingTimeHelper(post);
    const target = React.createRef();
    return (
        <>
            <MetaData data={data} location={location} type="article" />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <ReadingProgress target={target} />
            <Layout>
                <div className="post-section">
                    <div
                        className={`post-header item is-hero is-first ${
                            post.feature_image && "is-image"
                        } ${getTagClasses()}`}
                    >
                        <div className="item-container global-color">
                            <div className="item-image global-image global-color">
                                {post.feature_image ? (
                                    <figure className="post-feature-image">
                                        <img
                                            src={post.feature_image}
                                            alt={post.title}
                                        />
                                    </figure>
                                ) : null}
                            </div>
                            <div className="item-content">
                                <div className="item-meta global-meta">
                                    <span className="global-reading">
                                        {post.published_at_from_now} by{" "}
                                        <a href={post.primary_author.url}>
                                            {post.primary_author.name}
                                        </a>{" "}
                                        - {readingTime}
                                    </span>
                                </div>
                                <h2 className="item-title">{post.title}</h2>
                                {post.custom_excerpt && (
                                    <p className="item-excerpt">
                                        {post.custom_excerpt}
                                    </p>
                                )}
                                {post.tags && (
                                    <div className="item-tags global-tags">
                                        {post.tags.map((tag, index) => (
                                            <>
                                                {!tag.slug.includes("hash") && (
                                                    <a
                                                        key={index}
                                                        href={tag.url}
                                                        className="global-tags-hash-sign"
                                                    >
                                                        {tag.name}
                                                    </a>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <article className="article-content">
                        <div
                            className="post-content"
                            ref={target}
                            dangerouslySetInnerHTML={{
                                __html: post.childHtmlRehype.html,
                            }}
                        ></div>
                        <aside
                            className={`${!showAdvertisement && "display-add"}`}
                        >
                            <div className="template template-one">
                                <div>
                                    <img
                                        src={bullet}
                                        alt="notion bullet journal"
                                        className="template-image"
                                    />
                                </div>
                                <div className="template-info">
                                    <h1 className="template-heading">
                                        Notion Bullet Journal
                                    </h1>
                                    <p>
                                        A combination of notion and classic
                                        bullet journal.
                                    </p>
                                    <a
                                        href="https://prototion.com/notion-for/notion-bullet-journal?ref=xenox"
                                        className="advertisement-link"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Get Now
                                    </a>
                                </div>
                            </div>
                            <div className="template template-two">
                                <div>
                                    <img
                                        src={resolution}
                                        alt="Resolution board journal"
                                        className="template-image"
                                    />
                                </div>
                                <div className="template-info">
                                    <h1 className="template-heading">
                                        Resolution Board
                                    </h1>
                                    <p>
                                        Notion template to track all your new
                                        year goals.
                                    </p>
                                    <a
                                        href="https://prototion.com/notion-for/resolution-board?ref=xenox"
                                        className="advertisement-link"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Get Now
                                    </a>
                                </div>
                            </div>
                        </aside>
                    </article>
                    <div className={"advertisement-mobile"}>
                        <h1 className="advertisement-heading">
                            Are you interested in notion templates?
                        </h1>
                        <img
                            src="https://prototion-pile.fra1.digitaloceanspaces.com/prototion2.0/xenoxby%20advertisement.svg"
                            alt="illustration"
                            style={{ height: "150px" }}
                        />
                        <p className="advertisement-paragraph">
                            Buy and sell notion templates on
                        </p>
                        <p className="advertisement-logo">Prototion.com</p>
                        <a
                            href="https://prototion.com"
                            className="advertisement-link"
                        >
                            Visit Now
                        </a>
                    </div>
                </div>
                <Subscribe />
                {relatedPosts.length > 0 && (
                    <PostRelated posts={relatedPosts} />
                )}
                <PrevNext previous={previous} next={next} />
            </Layout>
        </>
    );
};

Post.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.array,
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            childHtmlRehype: PropTypes.shape({
                html: PropTypes.string.isRequired,
            }),
            feature_image: PropTypes.string,
            custom_excerpt: PropTypes.string,
            slug: PropTypes.string,
            primary_author: PropTypes.object,
            published_at_from_now: PropTypes.string,
            tags: PropTypes.array,
        }).isRequired,
    }).isRequired,
    pageContext: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default Post;

export const postQuery = graphql`
    query($slug: String!, $tagslug: String) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
        nextprev: allGhostPost(filter: { slug: { eq: $slug } }) {
            edges {
                next {
                    slug
                }
                previous {
                    slug
                }
            }
        }
        allGhostPost(
            limit: 4
            filter: {
                slug: { ne: $slug }
                tags: { elemMatch: { slug: { eq: $tagslug } } }
            }
        ) {
            nodes {
                primary_author {
                    name
                    slug
                }
                primary_tag {
                    slug
                }
                title
                slug
                url
            }
        }
    }
`;

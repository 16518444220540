import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const PrevNext = ({ previous, next }) => (
    <aside className="nextprev-section">
        {next && (
            <section className="nextprev-newer ">
                {next.feature_image && (
                    <Link
                        to={`/${next.slug}`}
                        className="nextprev-image global-image global-color"
                    >
                        <img src={next.feature_image} />
                    </Link>
                )}
                <div>
                    <small>Newer post</small>
                    <h3>
                        <Link to={`/${next.slug}`} className="global-underline">
                            {next.title}
                        </Link>
                    </h3>
                </div>
            </section>
        )}

        {previous && (
            <section className="nextprev-older ">
                <div>
                    <small>Older post</small>
                    <h3>
                        <Link
                            to={`/${previous.slug}`}
                            className="global-underline"
                        >
                            {previous.title}
                        </Link>
                    </h3>
                </div>
                {previous.feature_image && (
                    <Link
                        to={`/${previous.slug}`}
                        className="nextprev-image global-image global-color"
                    >
                        <img src={previous.feature_image} />
                    </Link>
                )}
            </section>
        )}
    </aside>
);

PrevNext.propTypes = {
    previous: PropTypes.object,
    next: PropTypes.object,
};

export default PrevNext;
